import React, { useState } from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'

import { red, orange, grey, green } from '@mui/material/colors'

import { ThemeProvider, createTheme } from "@mui/material/styles"

import CssBaseline from "@mui/material/CssBaseline"

export default function App() {
  const questions = require('./questions.json')

  let [currentQuestion, setCurrentQuestion] = useState(0)

  let [showScore, setShowScore] = useState(false)

  let [score, setScore] = useState(0)

  let [correct, setCorrect] = useState(false)

  let [hauling, setHauling] = useState("")

  const handleAnswerButtonClick = (isCorrect) => {
    let correctIndex = questions[currentQuestion].answerOptions.map(function (o) { return o.isCorrect }).indexOf(true)

    setHauling(questions[currentQuestion].answerOptions[correctIndex].answerText)

    if (isCorrect === true) {
      setCorrect(true)
    }

    let nextQuestion = currentQuestion + 1

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      setShowScore(true)
    }
  }

  const greyLight = grey[700]
  const greyDark = grey[900]
  const warning = red[700]
  const incorrectColor = orange['A700']
  const correctColor = green['A400']

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: greyLight,
      },
      secondary: {
        main: greyDark,
      },
    },
  });



  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box bgColor={greyDark} className='app' maxWidth={'750px'} m={'auto'} textAlign={'center'}>
        <Stack direction={'column'} spacing={2} >
          <Box >
            <Typography variant='h5' color={warning}>
              Only participate if you are a passenger.
            </Typography>
            <Typography variant='h5' color={warning}>
              Distracted driving is dangerous!
            </Typography>
            <Typography variant='h6' color={warning}>
              The gap in front of us is so we can stop safely. It is not for <stong>YOU</stong> or other vehicles. This gap is extremely important for the safety of everyone on the road.
            </Typography>
          </Box>
          <Box >
            {showScore ? (
              <Box className='score-section'  mb={'20px'}>
                {correct ? (
                  <>
                    <Typography color={correctColor} variant='h5'>Correct, the load is {hauling}</Typography>
                    <Typography color={correctColor} variant='h5'>Thanks for Playing!</Typography>
                  </>
                ) : (
                  <>
                    <Typography color={incorrectColor} variant='h5'>Sorry, that answer is incorrect. Your next guess is 50/50 chance!</Typography>
                    <Button onClick={() => { window.location.reload() }} size="large" variant="contained">
                      Try Again?
                    </Button>
                  </>
                )}
              </Box>
            ) : (
              <>
                <Box className='question-section'>
                  <Typography color={incorrectColor} variant='h6' className='question-text'>
                    {questions[currentQuestion].questionText}
                  </Typography>
                </Box>
                <Box className='answer-section'>
                  <Stack direction={'column'} spacing={2} sx={{width: '75%'}} m={'auto'} textAlign={'center'} mb={'20px'}>
                    {questions[currentQuestion].answerOptions.map((answerOption) => (
                      <Button textAlign={'center'} color={'primary'} size="large" variant="contained" onClick={() => handleAnswerButtonClick(answerOption.isCorrect)}>{answerOption.answerText}</Button>
                    ))}
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </Stack>
      </Box >
    </ThemeProvider>
  )
}
